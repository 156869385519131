





































































































































































































































































































import Vue from 'vue';
import { CurrencyType, VetAnimalType } from '@/models/user.model';
import { VetSkillType } from '@/models/position.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { db } from '@/main';
import {
  ContractType,
  OccupationType,
  PerkType,
  Position,
  WorkConditionsType
} from '@/models/position.model';
import router from '@/router';

export default Vue.extend({
  name: 'EditPosition',
  data() {
    return {
      step: 1,
      rules: {
        required: (value: string) => !!value || 'Required.',
        animalPreferences: (value: string[]) =>
          value?.length > 0 || 'Please select at least one',
        descriptionLength: (value: string) =>
          value?.length <= 500 || 'Max 500 characters',
        otherPerksLength: (value: string) =>
          !value || value?.length <= 250 || 'Max 250 characters',
        minDailySalary: (value: number) =>
          value > 10 || 'Minimum daily salary has to be higher than 10',
        minAnnualSalary: (value: number) =>
          value > 10000 || 'Minimum annual salary has to be higher than 10 000'
      },
      valid: false,
      contractTypes: Object.values(ContractType),
      vetAnimalTypes: Object.values(VetAnimalType),
      workConditionTypes: Object.values(WorkConditionsType),
      vetSkillTypes: Object.values(VetSkillType),
      currencyTypes: Object.values(CurrencyType),
      perkTypes: Object.values(PerkType),
      vetSkillType: VetSkillType,
      isFormValid: false,
      currency: CurrencyType.usd,
      position: {
        uid: db.collection('positions').doc().id,
        practiceUid: this.$store.state.currentUser.activePractice.uid,
        occupationType: OccupationType.veterinarian,
        internalReference: '',
        isApproved: false,
        isLive: false
      } as Position,
      isBusy: false,
      selectedVetSkills: [] as VetSkillType[],
      originalPosition: {},
      snackbar: false,
      snackbarText: '',
      snackbarColor: ''
    };
  },
  methods: {
    getData() {
      if (this.$route.query.positionUid as string) {
        this.isBusy = true;
        db.collection('positions')
          .doc(this.$route.query.positionUid as string)
          .get()
          .then(async (doc: firebase.firestore.DocumentData) => {
            this.position = doc.data() as Position;
            this.originalPosition = { ...this.position };
            if (this.position.vetSkillTypes) {
              this.selectedVetSkills = this.position.vetSkillTypes;
            }
            this.currency = this.position.currency as CurrencyType;
            this.isBusy = false;
          });
      }
    },
    updateData() {
      this.position.currency = this.currency;
      if (this.selectedVetSkills) {
        this.position.vetSkillTypes = this.selectedVetSkills;
      }
      db.collection('positions')
        .doc(this.position.uid)
        .set(this.position, { merge: true })
        .then(() => {
          router.push({
            name: 'PracticeProfile',
            query: { activeTab: '4' }
          });
        });
    },
    deleteData() {
      this.$confirm(`Are you sure you want to delete this role?`).then(res => {
        if (res) {
          db.collection('positions')
            .doc(this.position.uid)
            .delete()
            .then(() => {
              this.$router.push({ name: 'Positions' });
            });
        }
      });
    },
    setLive(live: boolean) {
      this.$confirm(
        `Are you sure you want to set this role ${
          live ? 'to Live' : 'to Paused'
        }?`
      ).then(res => {
        if (res) {
          this.position.isLive = live;
          db.collection('positions')
            .doc(this.position.uid)
            .set({ isLive: live }, { merge: true });
        }
      });
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  created() {
    this.getData();
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.position.title &&
      this.position.internalReference &&
      JSON.stringify(this.originalPosition) !== JSON.stringify(this.position)
    ) {
      db.collection('positions')
        .doc(this.position.uid)
        .set(this.position, { merge: true })
        .then(() => {
          this.showSnackbar('Position updated.', 'success');
        });
      setTimeout(() => {
        next();
      }, 1000);
    } else {
      next();
    }
  }
});
